
import styled from 'styled-components';
import VerticalSeperator from '../VerticalSeperator';
import ResumePdf from '../../assets/resume.pdf';
import PageTitle from '../PageTitle';


const Section = styled.div`
  position: relative;
  background: none;
  width: 100%;

  display: flex;
  justify-content: center;
  }
`;

const Container = styled.div`
  position: relative;
  background: none;
  width: ${(props) => props.theme.bodyWidthInner};
`;

const PdfBox = styled.div`
  margin-left: -80px;
`;


const Resume = () => {

  return (
    <Section>
      <Container id="about"> 
        <PageTitle title="RESUME"/>
        <PdfBox>
          <object 
            width="960px" 
            height="1110px" 
            data={ResumePdf} 
            type="application/pdf"
          > 
          </object>          
        </PdfBox>

      </Container>
    </Section>
  );
};

export default Resume;
