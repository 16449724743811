// This file contains variable for different themes

export const light = {
  body: "#FFFFFF",
  text: "#000000", 
  textBoldColor: "#3E3E3E", // black shade

  bodyRgba: "255, 255, 255",
  textRgba: "32,32,32",

  carouselColor: "#EEEDDE",
  backgroundColor: "#fdf8f4",

  fontxs: "0.75em",
  fontsm: "0.875em",
  fontmd: "1em", // 1em = 16px
  fontlg: "1.25em",
  fontxl: "2em",
  fontxxl:  "3em",
  fontxxxl: "4em",

  fontButton: "0.875em",

  navHeight: "96px",
  bodyWidth: "1200px",
  bodyWidthInner: "1200px",
  footerHeight: "204px",
};

export const dark = {
  body: "#000000",
  text: "#fff", // white shade
  bodyRgba: "0,0,0",
  textRgba: "255, 255, 255",

  carouselColor: "#EEEDDE",

  fontxs: "0.75em",
  fontsm: "0.875em",
  fontmd: "1em", // 1em = 16px
  fontlg: "1.25em",
  fontxl: "2em",
  fontxxl: "3em",
  fontxxxl: "4em",

  fontButton: "0.875em",

  navHeight: "180px",
  halfNavHeight: "-90px",
};
