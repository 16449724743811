import styled from 'styled-components';
import AboutPicture from '../../assets/arielpp.png';
import PageTitle from '../PageTitle';

const Section = styled.div`
  background: none;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 64em) {
    display: contents;
    width: 100vw;
  }
`;

const Container = styled.div`
  background: none;
  width: ${(props) => props.theme.bodyWidthInner};
  margin: ${(props) => props.margin};

  @media (max-width: 64em) {
    width: 90vw;
    margin: 0vw;
  }
`;

const HorizontalContainer = styled(Container)`
  display: flex;
  justify-content: center;

  @media (max-width: 64em) {
    flex-direction: column;
  }
`;

const Picture = styled.div`
  height: 30vh;
  margin-top: 24px;
  margin-bottom: 12px;

  @media (max-width: 64em) {
    width: 80vw;
    max-width: 80vw;
  }
`;

const Image = styled.img`
  height: 30vh;
  width: auto;
  overflow: hidden;
  border-radius: 20px;
  object-fit: cover;
  object-position: 50% 50%;    

  @media (max-width: 64em) {
    width: 80vw;
    max-width: 80vw;
  }
`;

const BodyText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 24px;  
  
  @media (max-width: 64em) {
    width: 90vw;
  }
`;


const About = () => {

  return (
    <Section>
      <Container id="about"> 
        <PageTitle title="ABOUT ME"/>
        <HorizontalContainer>        
          {/* <Picture>
            <Image src={AboutPicture}/>
          </Picture>
          <Container margin={'0px 48px'}>
            <BodyText>An innovative UX Researcher, Ariel Watson, is renowned for her strong background in Communication and Qualitative Research. Ariel possesses an exceptional ability to produce engaging content, manage diverse teams, and make significant contributions to dynamic UX Research teams.</BodyText>
            <BodyText>Ariel holds a Bachelor of Arts in Communication Studies from San Jose State University, with an emphasis on Communication Theory & Qualitative Research, and a minor in Environmental Studies. With her specialized skills in qualitative & mixed methods, interviews, surveys, focus groups, contextual inquiry, and more, she brings a holistic approach to her UX Research projects.</BodyText>
            <BodyText>Having worked as a UX Researcher for a Comprehensive PMDD App and Meta Horse Society, Ariel has proven her capacity to conduct comprehensive user interviews, surveys, and usability tests. She has developed efficient strategies that have significantly raised user engagement.</BodyText>
            <BodyText>With a unique blend of pattern detection abilities, strong visual memory, and exceptional attention to detail, Ariel excels in analyzing complex data to gain a comprehensive understanding of user behavior. Her background in the veterinary field has strengthened her ability to make prompt decisions and effectively communicate in high-stress and emotional situations.</BodyText>
            <BodyText>Ariel is passionately committed to finding a middle ground between user experience, a company's best interests, and stakeholder needs. She advocates for mental health and aims to help bridge the gap between these seemingly opposing forces. Her goal is to design products that are not only functional, profitable, and user-friendly but also ethical and responsible.</BodyText>
          </Container> */}
          <h1>IN PROGRESS</h1>
        </HorizontalContainer>
      </Container>
    </Section>
  );
};

export default About;
