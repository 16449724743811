import styled from 'styled-components';
import AboutPicture from '../../assets/arielpp.png';
import PageTitle from '../PageTitle';

const Section = styled.div`
  background: none;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 64em) {
    display: contents;
    width: 100vw;
  }
`;

const Container = styled.div`
  background: none;
  width: ${(props) => props.theme.bodyWidthInner};
  margin: ${(props) => props.margin};

  @media (max-width: 64em) {
    width: 90vw;
    margin: 0vw;
  }
`;

const HorizontalContainer = styled(Container)`
  display: flex;
  justify-content: center;

  @media (max-width: 64em) {
    flex-direction: column;
  }
`;

const Picture = styled.div`
  height: 30vh;
  margin-top: 24px;
  margin-bottom: 12px;

  @media (max-width: 64em) {
    width: 80vw;
    max-width: 80vw;
  }
`;

const Image = styled.img`
  height: 30vh;
  width: auto;
  overflow: hidden;
  border-radius: 20px;
  object-fit: cover;
  object-position: 50% 50%;    

  @media (max-width: 64em) {
    width: 80vw;
    max-width: 80vw;
  }
`;

const BodyText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 24px;  
  
  @media (max-width: 64em) {
    width: 90vw;
  }
`;


const Contact = () => {

  return (
    <Section>
      <Container id="contact"> 
        <PageTitle title="CONTACT"/>
        <HorizontalContainer>        
          <h1>IN PROGRESS</h1>
        </HorizontalContainer>
      </Container>
    </Section>
  );
};

export default Contact;
