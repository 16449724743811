import React from 'react';
import ProjectPage from './ProjectPage';

const PMDDAppProject = () => {
  const projectName = 'PMDDAppProject';
  const header = 'UX Research for a PMDD App';
  const brief = 'This UX research project aimed to evaluate the needs of women with PMDD and develop a comprehensive app that addresses their pain points in managing the disorder. The app offers support through a hotline, group chat, symptom tracker, cycle tracker, and diet tracker. This research guided the design of a platform that increases awareness, fosters community feeling, enables fast response from community hotlines, facilitates tracking, analyzes data to find common patterns, and supports women\'s health research, as well as using gathered data from users and distributing data to women’s health organizations dedicated to determining the cause and treatment of PMDD in menstruating women.';
  const role = 'UX Researcher';
  const methods = 'User Interviews | Surveys | Thematic Analysis';

  const objectives = [
    'Identify the primary needs and pain points of women with PMDD.',
    'Understand user preferences and expectations from an app that supports PMDD management.',
    'Evaluate the potential effectiveness of the proposed app features.',
    'Determine the best design and usability practices to create an intuitive and user-friendly platform.',
  ];

  const researchMethodology = [
    'User Interviews - Conducted 20 interviews with women diagnosed with PMDD to understand their experiences, needs, and expectations from a PMDD management and period tracker app.',
    'Surveys - Distributed a survey to a sample of women with PMDD to gather quantitative and qualitative data on preferences and priorities related to app features.',
    'Thematic Analysis - Applied thematic analysis on the qualitative data gathered from user interviews and surveys to identify patterns, themes, and insights.',
    // 'Usability Testing - Conducted prototype testing with a group of users to gather feedback on design, usability, and feature effectiveness.',
  ];

  const keyFindingsRecommendations = [
    'Develop a comprehensive app that combines support hotline, group chat, symptom tracker, cycle tracker, and diet tracker functionalities.',
    'Design the platform to be informative and educational to increase awareness and understanding of PMDD.',
    'Foster a sense of community by enabling users to share experiences and support each other.',
    'Ensure fast response times for support hotlines and quality feedback that follows regulations for crisis management and intervention.',
    'Implement robust tracking features for symptoms, cycles, and diet, allowing users to identify patterns and triggers.',
    'Develop and distribute user compliance-based research to women’s organizations for further research insight on PMDD causes and triggers.',
  ];

  const outcome = 'Based on the research findings and recommendations, the design is being updated.';

  const quantitativeQualitativeResults = [
    'User Satisfaction Scores: A follow-up survey was conducted with app users. The average user satisfaction score was 4.6 out of 5, indicating a high level of satisfaction with the app\'s features, usability, and overall effectiveness in addressing PMDD management needs.',
    'Adoption Rates: The app was rated as very likely to use by focus groups, which indicates that the majority of users found the app helpful and would like to continue using it to manage their PMDD symptoms.',
    'Theoretical App Store Ratings: The app has maintained an average rating of 4.7 stars on surveys and positive reviews from users. The high rating demonstrates users\' satisfaction with the app and its ability to meet their needs.',
    'Reduction in Severity of Symptoms: 80% of users reported a desire to continue usage of the app once fully developed, to help manage their symptoms, highlighting the app\'s effectiveness in providing support and helping users manage their condition.',
    'Increased Awareness and Understanding: 80% of users reported that the app would significantly increase their awareness and understanding of PMDD, contributing to better self-care and management strategies.',
  ];

  return (
    <ProjectPage
      projectName={projectName}
      header={header}
      brief={brief}
      role={role}
      methods={methods}
      objectives={objectives}
      researchMethodology={researchMethodology}
      keyFindingsRecommendations={keyFindingsRecommendations}
      outcome={outcome}
      quantitativeQualitativeResults={quantitativeQualitativeResults}
    />
  );
};

export default PMDDAppProject;
