import styled from 'styled-components';


const Section = styled.section`
  position: relative;
  height: 1px;
  background: #3E3E3E;
  margin: 80px 0px;
  width: ${(props) => props.theme.bodyWidth};
  z-index: 1;
`;


const VerticalSeperator = () => {
  return (
    <Section id="vertical_seperator"/>
  );
};

export default VerticalSeperator;
