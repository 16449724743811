import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { HashLink as HLink } from 'react-router-hash-link';


const NavBarContainer = styled.section`
  height: ${(props) => props.theme.navHeight};
  width: ${(props) => props.theme.bodyWidth};

  display: flex;
  justify-content: center; 
  
  @media (max-width: 64em) {
    max-width: 100vw;
  }
`;

const NavBarInnerContainer = styled.section`
  width: ${(props) => props.theme.bodyWidthInner};

  @media (max-width: 64em) {
    max-width: 100vw;
  }
`;

const NavBar = styled.nav`
  height: ${(props) => props.theme.navHeight};
  display: flex;
  justify-content: space-between;
  align-items: center;


  .mobile {
    display: none;
  }

  @media (max-width: 64em) {
    .desktop {
      display: none;
    }
    .mobile {
      display: inline-block;
    }
  }
`;

const NavBarRight = styled.nav`
  display: flex;
  justify-content: right;
  align-items: center;
  }
`;

const Name = styled.div`
  margin-left: 40px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;

`;

const Menu = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;

  @media (max-width: 70em) {
    position: fixed;
    top: ${(props) => props.theme.navHeight};
    left: 0;
    right: 0;
    bottom: 0;
    height: ${(props) => `calc(100vh - ${props.theme.navHeight})`};
    z-index: 50;
    background: ${(props) => `rgba(${props.theme.bodyRgba}, 0.85)`};
    backdrop-filter: blur(2px);

    transform: ${(props) =>
      props.click ? 'translateY(0)' : 'translateY(1000%)'};
    transition: all 0.3s ease;

    flex-direction: column;
    justify-content: center;
  }
`;

const MenuItem = styled.li`
  margin-right: 40px;
  color: ${(props) => props.theme.text};
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  // border-bottom: 3px #F7BE6D solid;


  &::after {
    content: ' ';
    display: block;
    width: 0%;
    height: 2px;
    background: ${(props) => props.theme.text};
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 100%;
  }

  @media (max-width: 64em) {
    margin: 1rem 0;

    &::after {
      display: none;
    }
  }
`;

const HamburgerMenu = styled.span`
  width: ${(props) => (props.click ? '2rem' : '1.5rem')};
  height: 2px;
  background: ${(props) => props.theme.text};

  position: absolute;
  top: 3rem;
  left: 90%;
  transform: ${(props) =>
    props.click
      ? 'translate(-50%) rotate(90deg)'
      : 'translate(-50%) rotate(0)'};

  display: none;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: all 0.3s ease;

  @media (max-width: 64em) {
    /* 1024px */
    display: flex;
  }

  &::after,
  &::before {
    content: ' ';
    width: ${(props) => (props.click ? '1rem' : '1.5rem')};
    height: 2px;
    right: ${(props) => (props.click ? '-2px' : '0')};
    background: ${(props) => props.theme.text};
    position: absolute;
    transition: all 0.3s ease;
  }

  &::after {
    top: ${(props) => (props.click ? '0.3rem' : '0.5rem')};
    transform: ${(props) => (props.click ? 'rotate(-40deg)' : 'rotate(0)')};
  }

  &::before {
    bottom: ${(props) => (props.click ? '0.3rem' : '0.5rem')};
    transform: ${(props) => (props.click ? 'rotate(40deg)' : 'rotate(0)')};
  }
`;

const Navigation = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <NavBarContainer id="navigation">
      <NavBarInnerContainer>
        <NavBar>
          <Name>
            <Link to="/">Ariel Guthrie Watson 
            </Link>
          </Name>
          <NavBarRight>
            <>
              <HamburgerMenu click={menuOpen} onClick={toggleMenu}>
                &nbsp;
              </HamburgerMenu>
              <Menu click={menuOpen}>
                <MenuItem>
                  <HLink to="/#portfolio" onClick={toggleMenu}>UXR Portfolio</HLink>
                </MenuItem>
                <MenuItem>
                  <Link to="/about" onClick={toggleMenu}>About</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/projects" onClick={toggleMenu}>Projects</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/contact" onClick={toggleMenu}>Contact</Link>
                </MenuItem>
              </Menu>
            </>
          </NavBarRight>
        </NavBar>
      </NavBarInnerContainer>
    </NavBarContainer>
  );
};

export default Navigation;
