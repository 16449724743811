import React, { useEffect } from 'react';
import styled from 'styled-components';
import PageTitle from "../../PageTitle";
import ABTest from './ABTest';

const Section = styled.div`
  position: relative;
  background: none;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  position: relative;
  background: none;
  width: ${(props) => props.theme.bodyWidthInner};
  margin: ${props => props.margin};

  @media (max-width: 64em) {
    width: 100%;
    margin: 0 1rem;
    box-sizing: border-box;
  }
`;

const ContentContainer = styled.div`
  margin: 24px 0;
`;

const Header = styled.h2`
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 12px;
`;

const List = styled.ul`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 12px;
  padding-left: 24px;
`;

const ListItem = styled.li`
  margin-bottom: 8px;
`;

const ProjectPage = (props) => {
  const {
    projectName,
    header,
    brief,
    role,
    methods,
    objectives,
    researchMethodology,
    keyFindingsRecommendations,
    outcome,
    quantitativeQualitativeResults,
    abTestData = null,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Section>
      <Container>
        {/* <PageTitle title={projectName} /> */}
        <ContentContainer>
          <Header>{header}</Header>
          <Text>{brief}</Text>

          {abTestData !== null && <ABTest abTestData={abTestData} />}

          <Text>Role: {role}</Text>
          <Text>Methods: {methods}</Text>

          <Header>Objectives</Header>
          <List>
            {objectives.map((objective, index) => (
              <ListItem key={index}>{objective}</ListItem>
            ))}
          </List>

          <Header>Research Methodology</Header>
          <List>
            {researchMethodology.map((method, index) => (
              <ListItem key={index}>{method}</ListItem>
            ))}
          </List>

          <Header>Quantitative and Qualitative Results</Header>
          <List>
            {quantitativeQualitativeResults.map((result, index) => (
              <ListItem key={index}>{result}</ListItem>
            ))}
          </List>
          
          <Header>Key Findings and Recommendations</Header>
          <List>
            {keyFindingsRecommendations.map((finding, index) => (
              <ListItem key={index}>{finding}</ListItem>
            ))}
          </List>

          <Header>Outcome</Header>
          <Text>{outcome}</Text>

        </ContentContainer>
      </Container>
    </Section>
  );
};

export default ProjectPage;
