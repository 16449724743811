import React from 'react';
import styled from 'styled-components';
import MobileDisplay from './MobileDisplay';
import MobileWebDisplay from './MobileWebDisplay';

const ABTestContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 900px; 
  margin: 24px auto;
  align-items: center;

  @media (max-width: 868px) { 
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }
`;

const MobileContainer = styled.div`
  @media (max-width: 768px) { 
    margin-bottom: 24px;
  }
`;

const Label = styled.div`
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
`;

const ABTest = ({ abTestData }) => (
  <ABTestContainer>
    <MobileContainer>
      <MobileDisplay
        screenshot={abTestData[0]}
      />
      <Label>Before</Label>
    </MobileContainer>
    <MobileContainer>
      <MobileWebDisplay
        websiteUrl={abTestData[1]}
      />
      <Label>After</Label>
    </MobileContainer>
  </ABTestContainer>
);

export default ABTest;
