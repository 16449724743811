import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import UXRPMDD from '../../assets/UXRmh.png';
import UXRVC from '../../assets/UXRvc.png';
import UXRNFT from '../../assets/horseShoe.GIF';
import { Link } from 'react-router-dom';

const Section = styled.section`
  position: relative;
`;

const Container = styled.div`
  position: relative;
`;

const HelloContainer = styled.div`
  position: relative;
  height: calc(100vh - 96px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em) {
    height: calc(${props => props.viewportHeight}px - 96px);
  }
`;

const Header = styled.h1`
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 24px;

  @media (max-width: 32em) {
    font-size: 22px;
    line-height: 22px;
  }
`;

const Text = styled.p`
  position: sticky;
  margin-bottom: ${props => props.marginBottom};
  margin-top: ${props => props.marginTop};
  background: none;
  color: ${props => props.color};
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 32em) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ImageBox = styled.div`
  height: 240px;
  width: 240px;
  min-width: 240px;
  overflow: hidden;
  border-radius: 40px;
  margin-top: 24px;
  transition: all 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 64em) {
    width: 60vw;
    min-width: 60vw;

    @media (max-width: 36em) {
      width: 90vw;
      min-width: 90vw;
    }
  }
`;

const Image = styled.img`
  height: 240px;
  width: auto;
  overflow: hidden;
  border-radius: 40px;
  object-fit: cover;
  object-position: 50% 50%;
  background: black !important;

  @media (max-width: 64em) {
    height: 100%;
  }
`;

const WorksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
`;

const UxrProjectCardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

  @media (max-width: 64em) {
    flex-direction: column;
  }
`;

const ProjectDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin-left: 48px;

  @media (max-width: 64em) {
    margin: 48px 24px;
    max-width: 90vw;
  }
`;

const ScrollDownText = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 24px;
  font-family: 'Dancing Script', cursive;
  z-index: 999;
  color: transparent;
  animation: slideIn 2s forwards;
  display: flex;
  align-items: center;

  svg {
    width: 20px; // Adjust as needed
    height: 20px; // Adjust as needed
    margin-left: 8px; // Space between the text and the arrow
    fill: currentColor; // Inherits the color from the parent element (optional)
  }

  @keyframes slideIn {
    from {
      right: -250px; // Updated based on the new content width
      color: transparent;
    }
    to {
      right: 20px;
      color: #000;
    }
  }

  &:hover {
    color: #333;
  }
`;

const TextNL = (props) => {
  return (
    <Text>
      {props.text.split('<br>').map((line, i) => 
        <Text key={i} marginBottom={'8px'}>
          {line}
          <br />
        </Text>
      )}
    </Text>
  );
};

const UxrProjectCard = (props) => {
  return (
    <Link to={props.redirect}>
      <UxrProjectCardContainer>
          <ImageBox>
              <Image src={props.image_source}/>
          </ImageBox>
          <ProjectDetails>
            <Header>{props.header}</Header>
            <TextNL  text={props.brief}/>
            <Text marginTop={'20px'}>Role: {props.role}</Text>
            <Text>Methods: {props.methods}</Text>
          </ProjectDetails>
      </UxrProjectCardContainer>
    </Link>
  );
};

const LandingPage = () => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateHeight = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateHeight);

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const scrollToPortfolio = () => {
    const portfolioElement = document.getElementById('portfolio');
    portfolioElement.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Section>
      <Container id="landing"> 
        <HelloContainer
          viewportHeight={viewportHeight}
        >
          <Header>Ariel UXR</Header>
          <Text marginBottom={'20px'}>I am a strong advocate for mental health, recognizing that each interaction with an app can have an immediate impact on a user's well-being.</Text>
          <Text marginBottom={'20px'}>By empathizing with the needs of users, while also considering business objectives, I believe it is possible to design products that are not only functional, profitable and user-friendly but also ethical and responsible.</Text>
        
          <ScrollDownText onClick={scrollToPortfolio}>
            See My Work
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M7 10l5 5 5-5H7z"/>
            </svg>
          </ScrollDownText>

        </HelloContainer>
        <WorksContainer id="portfolio">
          <UxrProjectCard
            image_source={UXRPMDD}
            color="black"
            redirect="/projects/pmdd_app"
            header="UX Research for a PMDD App"
            brief="Mixed method research project to evaluate the needs of women with PMDD and develop a comprehensive app that addresses their pain points in managing the disorder. <br>First PMDD app with cycle tracker, diet tracker, symptom tracker, support hotline, support group chat, and distributing analytics to women's health organizations dedicated to finding a root cause and cure for PMDD."
            role="Founder & UX Researcher"
            methods="User Interviews | Surveys | Thematic Analysis"
          />
          <UxrProjectCard
            image_source={UXRNFT}
            color="white"
            redirect="/projects/nft_project"
            header="NFT Release Strategy: A UX Research Study"
            brief="Quantitative research study to identify factors that affect potential buyers' decision-making when purchasing NFTs."
            role="UX Researcher"
            methods="User Interviews | Surveys | A/B Testing"
          />
          <UxrProjectCard
            image_source={UXRVC}
            color="white"
            redirect="/projects/video_conference"
            header="How Video Meetings Affect Self-Consciousness: A Mental Health & Qualitative UX Research Study"
            brief="In this qualitative research study, I investigated the impact of video meetings on self-consciousness and mental health."
            role="UX Researcher"
            methods="User Interviews | Questionnaires"
          />
        </WorksContainer>
      </Container>
    </Section>
  );
};

export default LandingPage;
