import React from 'react';
import styled from 'styled-components';
import LinkedinLogo from '../assets/linkedin_logo.png';


const FooterContainer = styled.div`
  height: ${(props) => props.theme.footerHeight};
  width: ${(props) => props.theme.bodyWidth};
  color: ${(props) => props.theme.text};

  position: relative;
  bottom: 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;    

  @media (max-width: 64em) {
    max-width: 90vw;
  }
`;

const FooterRow = styled.div`
  width: ${(props) => props.theme.bodyWidthInner};

  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.align};
  align-items: center;

  margin-right: 80px;  
  
  @media (max-width: 64em) {
    max-width: 90vw;
    margin: 0;
    flex-direction: column;
  }
`;

const RowText = styled.text`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: ${(props) => props.align};
`;

const RowTextBold = styled.text`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  color: ${(props) => props.theme.textBoldColor};
  text-align: ${(props) => props.align};
  margin-left: 4px;
`;

const IconList = styled.div`
  display: flex;
  align-items: center;
  width: min-content;
  height: 96px;

  & > * {
    margin-left: 0.5rem;
  }
`;

const Image = styled.img`
  height: auto;
  width: 32px;
  overflow: hidden;
  border-radius: 4px;
  object-fit: cover;
  object-position: 50% 50%;
`;

const Footer = () => {

  return (
    <FooterContainer id="footer">
        <FooterRow align="right">
          <RowText align="right">Contact me at</RowText>
          <RowTextBold align="right">arielguthriewatson@gmail.com</RowTextBold>
        </FooterRow>
        <FooterRow align="right">
          <IconList>
                <a
                  href="https://www.linkedin.com/in/arielguthriewatson/" target="_blank" rel="noopener noreferrer"
                >
                  <Image src={LinkedinLogo}/>
                </a>
            </IconList>    
        </FooterRow>
        <FooterRow align="center">
          <RowText align="center">Ariel Guthrie Watson © {new Date().getFullYear()}</RowText>
        </FooterRow>
    </FooterContainer>
  );
};

export default Footer;
