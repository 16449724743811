import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import { light } from "./styles/Themes";
import React from 'react';
import styled from "styled-components";
import {
  Route,
  Routes 
} from 'react-router-dom';

import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import LandingPage from "./components/Pages/LandingPage";
import About from "./components/Pages/About";
import Resume from "./components/Pages/Resume";
import PageTitle from "./components/PageTitle";
import Projects from "./components/Pages/Projects";
import PMDDAppProject from "./components/Pages/UXRProjects/PMDDAppProject";
import NFTProject from "./components/Pages/UXRProjects/NFTProject";
import VideoConferenceProject from "./components/Pages/UXRProjects/VideoConferenceProject";
import Contact from "./components/Pages/Contact";

function App() {

  const MainContainer = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
    background-color: #fdf8f4;
    
    *, a {
      background: inherit;
      background-color: inherit;
      ::-webkit-scrollbar {display:none;}
      -ms-overflow-style: none; /* IE 11 */
      scrollbar-width: none; /* Firefox 64 */
    }
`;

  const DisplayContainer = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: ${(props) => props.theme.bodyWidth};

    background-color: inherit;
        
    @media (max-width: 64em) {
      width: 90vw;
    }
  `;

  return (
    <>
      <GlobalStyles />
      <MainContainer>
        <ThemeProvider theme={light}>
          <Navigation />
          <DisplayContainer>
            <Routes>
              <Route 
                path="/" 
                element={<LandingPage/>} 
              />            
              <Route 
                path="/resume" 
                element={<Resume/>} 
              />          
              <Route 
                path="/about" 
                element={<About/>} 
              />
              <Route 
                path="/contact" 
                element={<Contact/>} 
              />         
              <Route 
                path="/projects" 
                element={<Projects/>} 
              />        
              <Route
                path="/projects/pmdd_app"
                element={<PMDDAppProject />}
              />
              <Route
                path="/projects/nft_project"
                element={<NFTProject />}
              />
              <Route
                path="/projects/video_conference"
                element={<VideoConferenceProject />}
              />
              <Route 
                path="*" 
                element={<PageTitle title="PAGE NOT FOUND!"/>} 
              />
            </Routes>
          </DisplayContainer>
          <Footer />
        </ThemeProvider>
      </MainContainer>
    </>
  );
}

export default App;
