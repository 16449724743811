import React from 'react';
import ProjectPage from './ProjectPage';

const VideoConferenceProject = () => {
  const projectName = 'VideoConferenceProject';
  const header = 'UX Research on Video Conference';
  const brief = 'This UX research project aimed to investigate the impact of video meetings on self-consciousness and mental health. The study involved conducting in-depth interviews with participants who had been working remotely and attending video meetings for at least six months. The research findings revealed the initial self-consciousness experienced by participants and how it evolved over time. It also highlighted the challenges of separating personal and work lives while working from home.';
  const role = 'UX Researcher';
  const methods = 'In-depth Interviews | Questionnaires';

  const objectives = [
    'Investigate the impact of video meetings on self-consciousness and mental health.',
    'Understand the challenges of remote work and video meetings.',
    'Identify strategies to promote well-being and work-life balance in a remote work culture.',
  ];

  const researchMethodology = [
    'In-depth Interviews - Conducted interviews with participants who had been working remotely and attending video meetings for at least six months.',
    'Questionnaires - Distributed questionnaires with qualitative open-ended questions and conducted thematic analysis on the responses.',
  ];

  const keyFindingsRecommendations = [
    'Initially, participants expressed self-consciousness regarding their appearance during video meetings.',
    "Over time, there was a significant shift in participants' focus. They became less concerned with their physical appearance, and more interested in intrinsic rewards.",
    'Participants reported difficulties in maintaining a clear boundary between personal and professional life while working from home.',
  ];
    
  const quantitativeQualitativeResults = [
    'At the outset, 65% of participants exhibited signs of self-consciousness.',
    "A transition was observed in participants' focus over time, with a substantial 75% becoming less preoccupied with their physical appearance.",
    'A significant 80% of participants indicated that separating personal and work lives presented a major challenge.',
  ];

  const outcome = 'The research findings shed light on the impact of video meetings on self-consciousness and mental health. They emphasized the importance of promoting well-being and work-life balance in a remote work culture. The insights from the research can be used to develop strategies and best practices for conducting effective and inclusive video meetings, while also supporting the mental health and well-being of remote workers.';



  return (
    <ProjectPage
      projectName={projectName}
      header={header}
      brief={brief}
      role={role}
      methods={methods}
      objectives={objectives}
      researchMethodology={researchMethodology}
      keyFindingsRecommendations={keyFindingsRecommendations}
      outcome={outcome}
      quantitativeQualitativeResults={quantitativeQualitativeResults}
    />
  );
};

export default VideoConferenceProject;
