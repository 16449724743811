
import styled from 'styled-components';
import SJSUPod from '../../assets/SJSUPod.jpg';
import DSTA from '../../assets/dsta.jpg';

const Section = styled.section`
  position: relative;
  background: none;
  width: 100%;
  }
`;

const Container = styled.section`
  position: relative;
  background: none;

  @media (max-width: 64em) {
    flex-direction: column-reverse;
  }
`;

const Header = styled.div`
  font-size: 26px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
   
  margin-bottom: 24px;

  @media (max-width: 32em) {
    font-size: 22px;
    line-height: 22px;
  }
`;

const Text = styled.div`
  position: sticky;
  margin-bottom: ${props => props.marginBottom};
  background: none;
  color: ${props => props.color};
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 32em) {
    font-size: 16px;
    line-height: 20px;
  }
`;


const ImageBox = styled.div`
  height: auto;
  width: 440px;
  overflow: hidden;
  border-radius: 8px;
  margin-top: 24px;
  transition: all 0.2s ease;

  display: flex; // Add this line
  justify-content: center; // Add this line
  align-items: center; // Add this line

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 64em) {
    width: 60vw;
    @media (max-width: 36em) {
      width: 90vw;
    }
  }
`;

const Image = styled.img`
  height: auto;
  width: 100%; // Change this line
  overflow: hidden;
  border-radius: 20px;

  @media (max-width: 64em) {
    width: 100%; // Change this line
  }
`;

const WorksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
`;

const UxrProjectCardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 64em) {
    display: contents;
  }
`;

const ProjectDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin-left: 48px;

    
  @media (max-width: 64em) {
    margin: 48px 24px;
    max-width: 90vw;
  }
`;

const ProjectCard = (props) => {
  return (
    <UxrProjectCardContainer>

      <ImageBox>
        <a href={props.redirect} target="_blank" rel="noopener noreferrer">
          <Image src={props.image_source}/>
          {/* <Text color={props.color}>{props.text}</Text> */}
        </a>
      </ImageBox>
      
      <ProjectDetails>
        <Header>
          {props.header}
        </Header>
        <Text marginBottom={'20px'} >{props.brief}</Text>
        <Text>Role: {props.role}</Text>
        <Text>Methods: {props.methods}</Text>
      </ProjectDetails>
    </UxrProjectCardContainer>
    );
};

const Projects = () => {

  return (
    <Section>
      <Container id="projects"> 
          <WorksContainer> 
            <ProjectCard
              image_source={DSTA}
              redirect="https://open.spotify.com/show/3Oc5P0BxNSM5AevSnIQNhF"
              header="Damn She Talks A Lot"
              brief="Damn She Talks A Lot Podcast"
              role="Creator"
              methods="Interviews | Design  | Production"
            />  
            <ProjectCard
              image_source={SJSUPod}
              redirect="https://podcasters.spotify.com/pod/show/sjsu-comm-central/"
              header="SJSU Communications Podcast"
              brief="This is podcast for SJSU Communications Department"
              role="Manager"
              methods="Management  | Production"
            />                 
          </WorksContainer>
      </Container>
    </Section>
  );
};

export default Projects;
