import React from 'react';
import ProjectPage from './ProjectPage';
import ABOLD from '../../../assets/ab_old.png';


const MHS_WEBSITE = 'http://www.metahorsesociety.com';

const NTFProject = () => {
  const projectName = 'NTFProject';
  const header = 'UX Research for an NTF Project';
  const brief = 'This UX research project aimed to identify factors affecting NFT purchasing decisions and develop an effective release strategy for an NFT project. The research involved conducting user interviews, surveys, and usability testing. The project successfully increased user engagement by implementing A/B testing techniques and identified that an incentive-driven model would drive increased engagement among users.';
  const role = 'UX Researcher';
  const methods = 'User Interviews | Surveys | Usability Testing';

  const objectives = [
    'Identify factors affecting NFT purchasing decisions.',
    'Develop an effective release strategy for the NFT project.',
    'Increase user engagement through A/B testing techniques.',
    'Identify the adoption of an incentive-driven model to drive increased engagement among users.',
  ];

  const researchMethodology = [
    'User Interviews - Conducted interviews with potential buyers to understand their decision-making process and motivations for purchasing NFTs.',
    'Surveys - Distributed a survey to gather quantitative and qualitative data on preferences and priorities related to NFT project features.',
    // 'Usability Testing - Conducted prototype testing with users to gather feedback on design, usability, and feature effectiveness.',
  ];

  const keyFindingsRecommendations = [
    'Buyers were more interested in immediate benefits and rewards rather than long-term promises.',
    'Buyers were hesitant to purchase NFTs with unclear or undefined future benefits.',
    'Implement an incentive-based system that gives free NFTs to holders for every 10% of NFT population sale.',
  ];

  const outcome = 'Based on the research findings, the NFT project team implemented an incentive-based release strategy. This strategy resulted in increased user engagement and sales, as well as the establishment of a loyal community of buyers who were invested in the project\'s success. The team received positive feedback from buyers, who appreciated the clear roadmap and immediate rewards offered by the incentive-driven model.';

  const quantitativeQualitativeResults = [
    'Increased engagement by 20% through the implementation of A/B testing techniques.',
    'Positive buyer response to the incentive-based system, indicating increased engagement and a sense of community ownership.',
  ];

  return (
    <ProjectPage
      projectName={projectName}
      header={header}
      brief={brief}
      role={role}
      methods={methods}
      objectives={objectives}
      researchMethodology={researchMethodology}
      keyFindingsRecommendations={keyFindingsRecommendations}
      outcome={outcome}
      quantitativeQualitativeResults={quantitativeQualitativeResults}
      abTestData={[ABOLD, MHS_WEBSITE]}
    />
  );
};

export default NTFProject;