import styled from 'styled-components';


const TitleContainer = styled.div`
  height: 130px;
  width: 100%;
  
  @media (max-width: 64em) {
    width: 90vw;
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;

  padding-left: 40px;
  padding-top: 42px;
`;



const PageTitle = (props) => {
  return (
    <TitleContainer id={`page_${props.title}`}>
      <Title>
        {props.title}
      </Title>
    </TitleContainer>
  );
};

export default PageTitle;
