import React from 'react';
import styled from 'styled-components';
import IPHONE from '../../../assets/iphone-14-pro-max.png';

const MobileFrame = styled.div`
  width: 343px;
  background: url('${IPHONE}') no-repeat center center !important;
  background-size: contain !important;
  position: relative;
  height: 700px;
  padding: 12px;
  z-index: 1;
`;


const ScreenshotContainer = styled.div`
  overflow-y: scroll;  
  ::-webkit-scrollbar {display:none;}
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */

  height: calc(100% - 3px); // Adjust the height according to your iPhone image
  width: calc(100% - 21px); // Adjust the width according to your iPhone image
  margin: 2px 4px 0px 10px; // Adjust the margin according to your iPhone image
  border-radius: 44px;
  background: black !important;
`;

const Screenshot = styled.img`
  width: 100%;
  height: auto;
`;

const MobileDisplay = ({ screenshot }) => (
  <MobileFrame>
    <ScreenshotContainer>
      <Screenshot src={screenshot} alt="Website screenshot" />
    </ScreenshotContainer>
  </MobileFrame>
);

export default MobileDisplay;
