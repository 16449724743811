import React from 'react';
import styled from 'styled-components';
import IPHONE from '../../../assets/iphone-14-pro-max.png';

const MobileFrame = styled.div`
  width: 343px;
  background: url('${IPHONE}') no-repeat center center !important;
  background-size: contain !important;
  position: relative;
  height: 700px;
  padding: 12px;
  z-index: 1;
`;

const ScreenshotContainer = styled.div`
  overflow: hidden; // Hide overflow content, which includes the scrollbar
  height: calc(100% - 3px);
  width: calc(100% - 21px);
  margin: 2px 4px 0px 10px;
  border-radius: 44px;
  background: black !important;
  position: relative;
  z-index: 0;
`;

const IframeWrapper = styled.div`
  width: calc(100% + 17px);  // Extend width to push scrollbar out of view
  height: 100%;
  overflow-y: scroll;
`;

const WebsiteIframe = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 36px;
`;

const MobileWebDisplay = ({ websiteUrl }) => {
  console.log('mobileWebDisplay', websiteUrl);
  return (
    <MobileFrame>
      <ScreenshotContainer>
        <IframeWrapper>
          <WebsiteIframe src={websiteUrl} title="Website preview" />
        </IframeWrapper>
      </ScreenshotContainer>
    </MobileFrame>
  );
};

export default MobileWebDisplay;
